import { Customer } from '../graphql/fragments/gql/Customer';

export const resolveCustomerDropdownOptionLabel = ({ custIndex }: Customer) => custIndex;

export const resolveCustomerLabel = (custNo: number, custIndex: string) => {
  return custNo.toString() === custIndex ? custIndex : `${custIndex} (${custNo})`;
};

export const customerReferencePlaceHolder = (
  customer: Customer | null | undefined,
): string | undefined => {
  if (!customer || !customer.customerReferenceRequired) {
    return undefined;
  }

  return customer.defaultCustomerReference
    ? customer.defaultCustomerReference
    : 'Reference required';
};

export function getValidCustomer(customer: Customer | null | undefined): Customer | null {
  return customer?.isValid ? customer : null;
}

export function getFirstValidCustomer(customers: Customer[]): Customer | null {
  return customers.find(getValidCustomer) ?? null;
}

export function getValidIntermodalCustomer(customer: Customer | null | undefined): Customer | null {
  return customer?.isIntermodal ? getValidCustomer(customer) : null;
}

export function getFirstValidIntermodalCustomer(customers: Customer[]): Customer | null {
  return customers.find(getValidIntermodalCustomer) ?? null;
}
