import { useMemo } from 'react';
import { getValidCustomer } from '../utils/customerUtils';
import { useAllCustomers } from './useAllCustomers';

export const useHasAnyValidCustomer = (intermodal = false) => {
  const customers = useAllCustomers();

  return useMemo(
    () => customers.some(c => (!intermodal || c.isIntermodal) && getValidCustomer(c)),
    [customers, intermodal],
  );
};
