import { Column } from '@stenajs-webui/core';
import { Banner, ResultListBanner, stenaHazardous, stenaPlugin } from '@stenajs-webui/elements';
import { useSelector } from 'react-redux';
import { NoValidCustomersBanner } from '../../../../common/header-banners/NoValidCustomersBanner';
import { Texts } from '../../../../common/texts';
import {
  getBannerVariantForMessageResultSeverity,
  getMessagesResult,
} from '../../../../common/update-information/utils/messagesResult';
import {
  getAllIntermodalCreateValidationErrors,
  getCreateIntermodalBookingCreateResult,
  getCreateIntermodalBookingFormState,
} from '../../../../services/intermodal/createIntermodalReducer';

const StatusBanner = () => {
  return <Banner variant={'info'} text={Texts.IntermodalCreate} />;
};

const BookingInfoBanners = () => {
  const { noOfPlugins, hazardousGoods } = useSelector(getCreateIntermodalBookingFormState);

  return (
    <>
      {hazardousGoods && (
        <Banner variant={'warning'} text={Texts.HazardousGoodsRequested} icon={stenaHazardous} />
      )}
      {noOfPlugins > 0 && <Banner variant={'info'} text={Texts.Plugin} icon={stenaPlugin} />}
    </>
  );
};

function ValidationErrorsBanners() {
  const allValidationErrors = useSelector(getAllIntermodalCreateValidationErrors);

  if (allValidationErrors.length === 0) {
    return null;
  }

  return (
    <ResultListBanner
      variant={'error'}
      bannerState={{
        headerText: 'Booking cannot be created',
        items: allValidationErrors.map(description => ({ text: description })),
      }}
    />
  );
}

function MessageResultBanners() {
  const createResult = useSelector(getCreateIntermodalBookingCreateResult);

  const messagesResult = createResult ? getMessagesResult(createResult) : null;

  if (!messagesResult) {
    return null;
  }

  const bannerVariant = getBannerVariantForMessageResultSeverity(messagesResult.severity);

  return (
    <ResultListBanner
      variant={bannerVariant}
      bannerState={{
        headerText: 'Booking was not created',
        items: messagesResult.messages.map(description => ({ text: description })),
      }}
    />
  );
}

export const CreateIntermodalInformationBanners = () => {
  return (
    <Column gap={1}>
      <NoValidCustomersBanner intermodal />
      <StatusBanner />
      <BookingInfoBanners />
      <ValidationErrorsBanners />
      <MessageResultBanners />
    </Column>
  );
};
