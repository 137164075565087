import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTourIds } from '../../../common/hooks/utils/newFeatureTourUtil';
import { Hotkeys } from '../../../common/hotkeys/utils/hotkeys';
import { routeCreators } from '../../../common/routes/AppRoutes';
import { isOnManagePage } from '../../../common/routes/routeUtils';
import { hideModal, showHotkeysOverview } from '../../../services/modal/modalActions';
import { openSidebar } from '../../../services/sidebar/sidebarActions';
import { getSidebarIsOpen } from '../../../services/sidebar/sidebarReducer';
import { stopNewFeatureTour } from '../../../services/tour/tourActions';
import {
  getIsNewFeatureTourRunning,
  getUnseenFeatureIds,
} from '../../../services/tour/tourReducer';
import { useShouldShowNewSubscriptions } from '../../user-subscriptions/util/migratedSubscription';
import { PortalTour } from '../PortalTour';
import { StepWithLifecycleEffects, TourDefinition } from '../Tour';
import { waitForElementToExist } from '../util/utils';

export interface StepWithLifecycleEffectsWithId extends StepWithLifecycleEffects {
  id?: string;
}

export const getNewFeatureTour = (
  subscriptionsEnabled: boolean,
): TourDefinition<StepWithLifecycleEffectsWithId> => {
  const tour: TourDefinition<StepWithLifecycleEffectsWithId> = {
    steps: [
      {
        content:
          'The dashboard now only shows the most commonly used functions. You can change what to show on the Settings page.',
        onEnter: async ({ location, navigate }) => {
          if (!isOnManagePage(location)) {
            navigate(routeCreators.bookings(), { replace: true });
          }
        },
        id: 'new-dashboard',
        target: '.tour_dashboard_default_filters',
        title: 'New dashboard',
      },
      {
        content:
          'Use the notification centre to see your recently created bookings. The Urgent message tab shows the messages related to your bookings.',
        id: 'notification-center-with-urgent-messages',
        target: '.tour_notification_center',
        title: 'Notification center',
      },
      {
        content: 'Download an Excel file with the current search result (max 1000 bookings).',
        onEnter: async () => {
          const tableActions = await waitForElementToExist('.t_table_actions');
          tableActions.click();
        },
        onExit: async () => {
          const tableActions = await waitForElementToExist('.t_table_actions');
          tableActions.click();
        },
        disableScroll: true,
        id: 'download-bookings-excel',
        target: '.tour_export_to_excel',
        title: 'Excel export',
      },
      {
        content: `Use keyboard shortcuts to speed up your work in the Bookings view. Press ${Hotkeys.HotkeysOverView} to show the shortcuts overview.`,
        onEnter: async ({ store }) => {
          store.dispatch(showHotkeysOverview());
        },
        onExit: async ({ store }) => {
          store.dispatch(hideModal());
        },
        id: 'keyboard-shortcuts',
        target: '.tour_shortcuts_overview',
        title: 'Keyboard shortcuts',
      },
    ],
  };

  if (subscriptionsEnabled) {
    tour.steps.push(
      {
        content: 'From the settings page, you can manage your notifications.',
        onEnter: async () => {
          const userButton = await waitForElementToExist('.t_user_button');
          userButton.click();
        },
        onExit: async () => {
          const userButton = await waitForElementToExist('.t_user_button');
          userButton.click();
        },
        id: 'user-subscriptions-settings-link',
        target: '.tour_settings_link',
        title: 'Email and SMS notifications',
      },
      {
        content:
          'You can choose to receive notifications about specific events related to your bookings.',
        onEnter: async ({ navigate }) => {
          navigate(routeCreators.settings(), { replace: true });
        },
        onExit: async ({ navigate }) => {
          navigate(routeCreators.bookings(), { replace: true });
        },
        id: 'user-subscriptions-settings-page',
        target: '.tour_user_subscriptions_settings',
        title: 'Email and SMS notifications',
      },
    );
  }

  return tour;
};

const skipTour: TourDefinition = {
  steps: [
    {
      placement: 'right',
      content: 'You can always watch this again from the Help page.',
      target: '.tour_help',
      onEnter: async ({ store }) => {
        if (!getSidebarIsOpen(store.getState())) {
          store.dispatch(openSidebar());
          await new Promise(resolve => {
            setTimeout(resolve, 250);
          });
        }
      },
      title: 'Want to watch new features another time?',
      offset: 30,
    },
  ],
};

function removeAlreadySeenSteps(
  tour: TourDefinition<StepWithLifecycleEffectsWithId>,
  unseenIds: string[],
): TourDefinition<StepWithLifecycleEffectsWithId> {
  return {
    ...tour,
    steps: tour.steps.filter(s => s.id == null || unseenIds.includes(s.id)),
  };
}

export const NewFeatureTour: FC = () => {
  const dispatch = useDispatch();
  const shouldShowNewSubscriptions = useShouldShowNewSubscriptions();

  const newFeatureTour = useMemo(
    () => getNewFeatureTour(shouldShowNewSubscriptions),
    [shouldShowNewSubscriptions],
  );

  const shouldFeatureTourRun = useSelector(getIsNewFeatureTourRunning);
  const unseenFeatureIds = useSelector(getUnseenFeatureIds);
  const newFeatureTourWithCorrectTargets = useMemo(
    () => removeAlreadySeenSteps(newFeatureTour, unseenFeatureIds),
    [unseenFeatureIds, newFeatureTour],
  );

  const onFinished = () => dispatch(stopNewFeatureTour(getTourIds(newFeatureTour.steps)));

  if (newFeatureTourWithCorrectTargets.steps.length === 0) {
    return null;
  }

  return (
    <PortalTour
      mainTour={newFeatureTourWithCorrectTargets}
      skipTour={skipTour}
      onFinished={onFinished}
      active={shouldFeatureTourRun}
      trackerCategory={'NewFeatureTour'}
    />
  );
};
