import styled from '@emotion/styled';
import { Box, Row } from '@stenajs-webui/core';
import { stenaSearch } from '@stenajs-webui/elements';
import { Drawer } from '@stenajs-webui/modal';
import {
  NavBar,
  NavBarButton,
  NavBarHeading,
  SidebarMenu,
  SidebarRailMenu,
} from '@stenajs-webui/panels';
import cx from 'classnames';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { BoxWithPrintHide } from '../../../common-ui/BoxWithPrintHide';
import { showSearchOverlay } from '../../../services/search/searchActions';
import { useAccount } from '../../../services/settings/hooks/useAccount';
import {
  closeSidebar,
  disableSidebarRail,
  enableSidebarRail,
  toggleSidebar,
} from '../../../services/sidebar/sidebarActions';
import { getRailIsEnabled, getSidebarIsOpen } from '../../../services/sidebar/sidebarReducer';
import { useAuth } from '../../../use-cases/auth/AuthContext';
import { BookingSearch } from '../../../use-cases/manage/search/components/BookingSearch';
import { NotificationCenterIcon } from '../../../use-cases/notifications/components/NotificationCenterIcon';
import { FrontendOutOfDateBanner } from '../../header-banners/FrontendOutOfDateBanner';
import { MaintenanceMessageBanner } from '../../header-banners/MaintenanceMessageBanner';
import { useAuthState } from '../../hooks/useAuthState';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import { getModalRoot } from '../../modal/FreightPortalBaseModal';
import {
  getMainPage,
  isOnManagePage,
  isOnPaymentPage,
  isOnSimpleCreatePage,
} from '../../routes/routeUtils';
import { trackEvent } from '../../tracking/trackerService';
import { zIndices } from '../../zIndices';
import { AccountLoggedIn } from './AccountLoggedIn';
import { SidebarBottomItems, SidebarTopItems } from './SidebarItems';

const StyledNavBar = styled(NavBar)`
  & > div:nth-of-type(2) {
    flex: 0 1 350px;
  }
`;

const HeaderWrapper = styled('div')`
  .manage-page & {
    position: sticky;
    left: 0;
    width: 100dvw;
  }

  @media print {
    -webkit-print-color-adjust: exact !important;
    display: block;
    width: 100%;
    height: 60px;
  }
`;

export const useRailEnabled = () => useSelector(getRailIsEnabled);

export const Header: FC = () => {
  const auth = useAuth();
  const { isAuthenticated } = useAuthState();
  const sidebarIsOpen = useSelector(getSidebarIsOpen);
  const isDesktop = useIsDesktop();
  const account = useAccount();
  const drawerWidth = !isDesktop ? '100dvw' : '280px';

  const railEnabled = useRailEnabled() && isDesktop;

  const railMenuLabel = railEnabled ? 'Unpin menu' : 'Pin menu';

  const dispatch = useDispatch();

  const location = useLocation();

  const showSearch =
    isAuthenticated &&
    (isOnManagePage(location) || isOnSimpleCreatePage(location) || isOnPaymentPage(location));

  const onLogOut = () => {
    window.sessionStorage.clear();
    auth.logout();
  };

  const stenaLineLink = (
    <Link to={getMainPage(account.permissions)} style={{ textDecoration: 'none' }}>
      <NavBarHeading>Freight Portal</NavBarHeading>
    </Link>
  );

  const right = (
    <Row alignItems={'center'} gap={2}>
      {!isDesktop && showSearch && (
        <NavBarButton
          className={'t_search'}
          leftIcon={stenaSearch}
          onClick={() => dispatch(showSearchOverlay())}
        />
      )}
      <AccountLoggedIn onLogOut={onLogOut} />
      <NotificationCenterIcon />
    </Row>
  );

  return (
    <>
      <HeaderWrapper>
        <StyledNavBar
          showMenuButton={!railEnabled}
          left={stenaLineLink}
          className={'t_main_navbar tour_navbar'}
          // TODO: menuButtonVisibility={railEnabled ? 'hidden' : 'visible'}
          onClickMenuButton={() => dispatch(toggleSidebar())}
          center={showSearch && <BookingSearch isWide={isDesktop} />}
          right={right}
        />
        <Box style={{ marginLeft: 'var(--nav-menu-width)' }}>
          <FrontendOutOfDateBanner />
          <MaintenanceMessageBanner />
        </Box>
      </HeaderWrapper>
      <Drawer
        isOpen={sidebarIsOpen}
        onRequestClose={() => dispatch(closeSidebar())}
        zIndex={zIndices.drawer}
        portalTarget={getModalRoot('routeless-modal-root')}
        width={drawerWidth}
      >
        <SidebarMenu
          onCloseClick={() => dispatch(closeSidebar())}
          pinButtonVisible
          isPinned={railEnabled}
          onClickPinButton={() => {
            trackEvent({ category: 'Navigation', action: railMenuLabel });
            if (railEnabled) {
              dispatch(disableSidebarRail());
            } else {
              dispatch(enableSidebarRail());
            }
            dispatch(closeSidebar());
          }}
          bottomItems={
            <SidebarBottomItems
              onRequestClose={() => dispatch(closeSidebar())}
              location={location}
            />
          }
        >
          <SidebarTopItems onRequestClose={() => dispatch(closeSidebar())} location={location} />
        </SidebarMenu>
      </Drawer>
      {railEnabled && (
        <BoxWithPrintHide zIndex={zIndices.drawer} className={cx('tour_rail', 't_pinned_sidebar')}>
          <SidebarRailMenu
            onClickMenuButton={() => dispatch(toggleSidebar())}
            closeButtonVisible
            onClickCloseButton={() => {
              trackEvent({ category: 'Navigation', action: railMenuLabel });
              dispatch(disableSidebarRail());
            }}
            bottomItems={
              <SidebarBottomItems
                onRequestClose={() => dispatch(closeSidebar())}
                location={location}
                rail
              />
            }
          >
            <SidebarTopItems
              onRequestClose={() => dispatch(closeSidebar())}
              location={location}
              rail
            />
          </SidebarRailMenu>
        </BoxWithPrintHide>
      )}
    </>
  );
};
