import { Banner } from '@stenajs-webui/elements';
import { useHasAnyValidCustomer } from '../graphql/useHasAnyValidCustomer';

interface NoValidCustomersBannerProps {
  intermodal?: boolean;
}

export function NoValidCustomersBanner({ intermodal = false }: NoValidCustomersBannerProps) {
  const hasAnyValidCustomer = useHasAnyValidCustomer(intermodal);

  if (hasAnyValidCustomer) {
    return null;
  }

  return (
    <Banner
      variant={'warning'}
      text={'You have no account with booking privileges. Contact Stena Line to set up an account.'}
    />
  );
}
