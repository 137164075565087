import { DropdownOption, SelectProps, SelectVariant } from '@stenajs-webui/select';
import { ReactElement } from 'react';
import { Options, SelectComponentsConfig } from 'react-select';
import { TrackerCategory } from '../../common/tracking/trackerService';
import { isDefined } from '../../common/utils/isDefined';
import { useDropdownField } from '../../use-cases/multiple-create/hooks/useDropdownField';
import { TrackerAction } from '../../use-cases/simple-create/hooks/UseTrackingTextInput';
import { Dropdown, GroupedOptionsType } from './Dropdown';

export interface CommonDropdownFieldProps<TValue>
  extends Omit<
    Partial<SelectProps<TValue>>,
    'isDisabled' | 'onChange' | 'options' | 'components' | 'value' | 'filterOption'
  > {
  disabled?: boolean;
  options:
    | GroupedOptionsType<DropdownOption<NonNullable<TValue>>>
    | Options<DropdownOption<NonNullable<TValue>>>;
  transformOption: (v: NonNullable<TValue>) => DropdownOption<NonNullable<TValue>>;
  variant?: SelectVariant;
  isValid?: boolean;
  trackerCategory: TrackerCategory;
  onValueChange: (value: TValue) => void;
  editable?: boolean;
  changed?: boolean;
  value: TValue | undefined;
  trackerAction: TrackerAction;
  components?: SelectComponentsConfig<DropdownOption<NonNullable<TValue>>, false, any>;
}

export function CommonDropdownField<TValue>({
  transformOption,
  disabled,
  options,
  variant,
  isValid = true,
  onValueChange,
  editable,
  changed,
  value,
  trackerAction,
  trackerCategory,
  menuPortalTarget,
  components,
}: CommonDropdownFieldProps<TValue>): ReactElement<CommonDropdownFieldProps<TValue>> {
  const internalOnChange = (value: TValue | undefined) => {
    if (isDefined(value)) {
      onValueChange?.(value);
    }
  };

  const { onChange: dropdownOnChange, value: dropdownValue } = useDropdownField({
    onChange: internalOnChange,
    options,
    transformOption,
    value,
    trackerCategory,
    trackerAction,
  });

  return (
    <Dropdown
      menuPortalTarget={menuPortalTarget}
      components={components}
      onChange={dropdownOnChange}
      options={options}
      isDisabled={disabled || !editable}
      value={dropdownValue ?? null}
      isEdited={changed}
      variant={isValid ? variant : 'error'}
    />
  );
}
