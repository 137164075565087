import { SagaIterator, Task } from 'redux-saga';
import { call, fork, join, put, select } from 'redux-saga/effects';
import {
  fetchSailings,
  fetchSenderReceiverCountries,
  fetchVehicleTypeOptionsCached,
} from '../../../common/api';
import { Customer } from '../../../common/graphql/fragments/gql/Customer';
import { Route } from '../../../common/graphql/fragments/gql/Route';
import { Sailing } from '../../../common/graphql/fragments/gql/Sailing';
import { SenderReceiverCountry } from '../../../common/graphql/fragments/gql/SenderReceiverCountry';
import { VehicleType } from '../../../common/graphql/fragments/gql/VehicleType';
import { customsClassificationDropdownEmptyOption } from '../../../common/graphql/useCustomsClassificationsOptions';
import { transformCountryOptions } from '../../../common/graphql/useSenderReceiverCountryOptions';
import {
  handleVehicleRegNoRequest,
  RegistrationNumberAlertStateChange,
} from '../../../common/registrationNumberAlert/sagas/registrationNumberAlertsSaga';
import { getAppliedDimension } from '../../../common/registrationNumberAlert/utils/registrationNumberAlertUtil';
import { shouldClearUkCustomsClassification } from '../../../common/utils/customsClassificationUtil';
import { getNewBookableSailing } from '../../../common/utils/sailingUtils';
import { formatRegistrationNumberString } from '../../../common/utils/stringUtil';
import { ColumnId } from '../../../gql/graphql';
import {
  getFieldsToShow,
  isAnyVisible,
} from '../../../use-cases/manage/details/utils/detailsUtils';
import { transformUpdateBookingFormStateToMinimalBooking } from '../../../use-cases/manage/details/utils/minimalBooking';
import { CustomsClassification } from '../../../use-cases/manage/grid/utils/optionTransformers';
import { getNumberOfDrivers } from '../../create/utils/transformCreateBookingResults';
import { RegistrationNumberAlertFormState, TravelledVehicleType } from '../registrationNumberAlert';
import {
  setRegistrationNumberAlert,
  setUpdateValues,
  triggerFormUpdateSaga,
  TriggerFormUpdateSagaAction,
  updateFinished,
  UpdateFormUpdateFormStateAction,
  updateRegistrationNumberAlert,
  updateStarted,
  updateValues,
} from '../updateFormActions';
import {
  BookingFormState,
  BookingFormStateKeys,
  FormInstanceId,
  getEditedFormState,
  getRegistrationNumberAlert,
} from '../updateFormReducer';

function registrationNumberAlertForVehicleTypeExists(
  registrationNumberAlert: RegistrationNumberAlertFormState | undefined,
  vehicleType: VehicleType,
) {
  return (
    registrationNumberAlert?.result &&
    registrationNumberAlert.result.vehicleTypeCode === vehicleType.code
  );
}

export function* setCountries(
  id: FormInstanceId,
  state: BookingFormState,
  route: Route | null | undefined,
  override = false,
) {
  const booking: ReturnType<typeof transformUpdateBookingFormStateToMinimalBooking> = yield call(
    transformUpdateBookingFormStateToMinimalBooking,
    state,
  );
  const fields: ReturnType<typeof getFieldsToShow> = yield call(
    getFieldsToShow,
    booking,
    Boolean(state.customer?.isCashCustomer),
  );

  if (!isAnyVisible(fields, [ColumnId.SenderCountryCode, ColumnId.ReceiverCountryCode])) {
    return;
  }

  const countries: SenderReceiverCountry[] = yield call(fetchSenderReceiverCountries);
  const countryOptions = transformCountryOptions(countries);

  if (countryOptions.length === 0) {
    return;
  }

  if (route && route.arrivalCountryCode !== '' && (!override || !state.receiverCountry)) {
    yield put(
      updateValues(id, {
        receiverCountry: countryOptions.find(country => country.value === route.arrivalCountryCode)
          ?.data,
      }),
    );
  }

  if (route && route.departureCountryCode !== '' && (!override || !state.senderCountry)) {
    yield put(
      updateValues(id, {
        senderCountry: countryOptions.find(country => country.value === route.departureCountryCode)
          ?.data,
      }),
    );
  }
}

export function* clearImportExportReferences(id: FormInstanceId) {
  yield put(
    updateValues(id, {
      importReference: '',
      exportReference: '',
    }),
  );
}

export function* onChangeCargoWeight(action: TriggerFormUpdateSagaAction<number | undefined>) {
  const {
    payload: { id, name, value: cargoWeight },
  } = action;

  if (name !== 'cargoWeight' || cargoWeight == null) {
    return;
  }

  const state: ReturnType<typeof getEditedFormState> = yield select(getEditedFormState, id);

  if (state) {
    yield fork(setCountries, id, state, state.route);
  }
}

export function* onChangeVehicleType(action: TriggerFormUpdateSagaAction<VehicleType | undefined>) {
  const {
    payload: { id, name, value: vehicleType },
  } = action;

  if (name !== 'vehicleType' || !vehicleType) {
    return;
  }

  const state: ReturnType<typeof getEditedFormState> = yield select(getEditedFormState, id);
  const registrationNumberAlert: ReturnType<typeof getRegistrationNumberAlert> = yield select(
    getRegistrationNumberAlert,
    id,
  );

  if (state) {
    yield fork(setCountries, id, state, state.route);

    if (!registrationNumberAlertForVehicleTypeExists(registrationNumberAlert, vehicleType)) {
      const { defaultWidth, defaultHeight, defaultLength, defaultTradeWeight } = vehicleType;
      const defaultNoOfDrivers = getNumberOfDrivers(vehicleType);

      yield joinUpdateValuesAction(id, {
        height: defaultHeight,
        length: defaultLength,
        noOfDrivers: defaultNoOfDrivers,
        tradeWeight: defaultTradeWeight,
        width: defaultWidth,
      });

      yield put(setRegistrationNumberAlert(id, undefined));
    } else {
      if (registrationNumberAlert?.result) {
        const { result } = registrationNumberAlert;
        const appliedHeight = getAppliedDimension(result.height, vehicleType.defaultHeight);
        const appliedLength = getAppliedDimension(result.length, vehicleType.defaultLength);
        const defaultNoOfDrivers = getNumberOfDrivers(registrationNumberAlert.vehicleType);

        yield put(
          updateValues(id, {
            height: appliedHeight ? result.height : vehicleType.defaultHeight,
            length: appliedLength ? result.length : vehicleType.defaultLength,
            noOfDrivers: defaultNoOfDrivers,
            width: registrationNumberAlert.vehicleType?.defaultWidth ?? vehicleType.defaultWidth,
          }),
        );
        yield put(
          updateRegistrationNumberAlert(id, {
            appliedTravelledVehicle: true,
            appliedHeight,
            appliedLength,
          }),
        );
      }
    }
  }
}

function* setUkCustomsClassification(
  id: FormInstanceId,
  departureDate: string,
  departureTime: string,
  route: Route,
  previousCustomsClassification: CustomsClassification,
) {
  if (
    shouldClearUkCustomsClassification(
      departureDate,
      departureTime,
      route,
      previousCustomsClassification,
    )
  ) {
    yield put(
      updateValues(id, { customsClassification: customsClassificationDropdownEmptyOption.data }),
    );
  }
}

export function* onChangeRoute(action: TriggerFormUpdateSagaAction<Route | undefined>) {
  const {
    payload: { id, name, value: route },
  } = action;

  const state: ReturnType<typeof getEditedFormState> = yield select(getEditedFormState, id);

  if (route && state) {
    yield fork(setCountries, id, state, route, true);
  }

  if (name !== 'route' || !route) {
    return;
  }

  if (!state) {
    return;
  }

  const vehicleTypes: VehicleType[] = yield call(
    fetchVehicleTypeOptionsCached,
    state.customer!.custNo,
    route.code,
  );

  yield fork(setCountries, id, state, state.route);
  yield fork(clearImportExportReferences, id);

  if (state.vehicleType && state.departureDate) {
    const vehicleTypeInState = state.vehicleType;

    const vehicleTypeOnRoute = vehicleTypes.find(v => v.code === vehicleTypeInState.code);

    if (!vehicleTypeOnRoute) {
      yield joinUpdateValuesAction(id, {
        vehicleType: undefined,
      });
    }

    let sailing: Sailing | null = null;

    if (state.departureDate) {
      const sailings: Awaited<ReturnType<typeof fetchSailings>> = yield call(
        fetchSailings,
        route.code,
        state.departureDate,
      );

      sailing = getNewBookableSailing(sailings, state.sailing);

      if (sailing && state.route && state.customsClassification) {
        yield fork(
          setUkCustomsClassification,
          id,
          state.departureDate,
          sailing.departureTime,
          state.route,
          state.customsClassification,
        );
      }
    }

    yield joinUpdateValuesAction(id, { sailing: sailing ?? undefined });
  }
}

export function* onChangeCustomer(action: TriggerFormUpdateSagaAction<Customer | undefined>) {
  const {
    payload: { id, name, value: customer },
  } = action;

  if (name !== 'customer' || !customer) {
    return;
  }

  const formState: ReturnType<typeof getEditedFormState> = yield select(getEditedFormState, id);

  if (!formState) {
    return;
  }

  yield fork(setCountries, id, formState, formState.route);

  if (formState.route && formState.vehicleType) {
    const vehicleTypes: VehicleType[] = yield call(
      fetchVehicleTypeOptionsCached,
      customer.custNo,
      formState.route.code,
    );

    const vehicleTypeInState = formState.vehicleType;

    const vehicleTypeOnRoute = vehicleTypes.find(v => v.code === vehicleTypeInState.code);

    if (!vehicleTypeOnRoute) {
      yield joinUpdateValuesAction(id, {
        vehicleType: undefined,
      });
    }
  }
}

export function* onChangeDepartureDate(action: TriggerFormUpdateSagaAction<string | undefined>) {
  const {
    payload: { id, name, value: departureDate },
  } = action;

  if (name !== 'departureDate' || !departureDate) {
    return;
  }

  const state: ReturnType<typeof getEditedFormState> = yield select(getEditedFormState, id);

  if (!state) {
    return;
  }

  let sailing: Sailing | null = null;

  if (state.route) {
    const sailings: Awaited<ReturnType<typeof fetchSailings>> = yield call(
      fetchSailings,
      state.route.code,
      departureDate,
    );

    sailing = getNewBookableSailing(sailings, state.sailing);

    if (sailing && state.customsClassification) {
      yield fork(
        setUkCustomsClassification,
        id,
        sailing.departureDate,
        sailing.departureTime,
        state.route,
        state.customsClassification,
      );
    }
  }

  yield joinUpdateValuesAction(id, { sailing: sailing ?? undefined });
}

export function* onChangeVehicleRegistration(
  action: TriggerFormUpdateSagaAction<string | undefined>,
) {
  const {
    payload: { id, name, value },
  } = action;

  if (value === undefined) {
    return;
  }

  const vehicleRegNo = formatRegistrationNumberString(value);

  const state: ReturnType<typeof getEditedFormState> = yield select(getEditedFormState, id);
  const previousRegistrationNumberAlert: ReturnType<typeof getRegistrationNumberAlert> =
    yield select(getRegistrationNumberAlert, id);

  if (!state || !state.route) {
    return;
  }

  const travelledVehicleType =
    name === 'vehicleRegNo' ? TravelledVehicleType.Vehicle : TravelledVehicleType.Trailer;

  const newRegistrationNumberAlertResult: RegistrationNumberAlertStateChange | null = yield call(
    handleVehicleRegNoRequest,
    state.customer!.custNo,
    state.route.code,
    vehicleRegNo,
    travelledVehicleType,
    state.cargoWeight,
    state.hazardousGoods,
    state.vehicleType || null,
    state.height,
    state.length,
    state.loadingListMessage,
  );

  if (newRegistrationNumberAlertResult) {
    yield put(
      setRegistrationNumberAlert(
        id,
        transformRegistrationNumberAlert(newRegistrationNumberAlertResult),
      ),
    );
    yield put(
      setUpdateValues(
        id,
        transformRegistrationNumberAlertToBookingFormState(newRegistrationNumberAlertResult, state),
      ),
    );
  } else if (
    previousRegistrationNumberAlert &&
    previousRegistrationNumberAlert.travelledVehicleType === travelledVehicleType
  ) {
    yield put(setRegistrationNumberAlert(id, undefined));
  }
}

const transformRegistrationNumberAlert = (
  alert: RegistrationNumberAlertStateChange,
): RegistrationNumberAlertFormState => ({
  appliedArrivalNotepad: alert.registrationNumberAlert.appliedArrivalNotepad,
  appliedCargoWeight: alert.registrationNumberAlert.appliedCargoWeight,
  appliedHazardousGoods: alert.registrationNumberAlert.appliedHazardousGoods,
  appliedHeight: alert.registrationNumberAlert.appliedHeight,
  appliedLength: alert.registrationNumberAlert.appliedLength,
  appliedLoadingListMessage: alert.registrationNumberAlert.appliedLoadingListMessage,
  appliedTravelledVehicle: alert.registrationNumberAlert.appliedTravelledVehicle,
  result: alert.registrationNumberAlert.result,
  travelledVehicleType: alert.registrationNumberAlert.travelledVehicleType,
  vehicleType: alert.registrationNumberAlert.vehicleType,
});

const transformRegistrationNumberAlertToBookingFormState = (
  alert: RegistrationNumberAlertStateChange,
  state: BookingFormState,
): Partial<BookingFormState> => ({
  cargoWeight: alert.cargoWeight || state.cargoWeight,
  hazardousGoods: alert.hazardousGoods !== undefined ? alert.hazardousGoods : state.hazardousGoods,
  height: alert.height || state.height,
  length: alert.length || state.length,
  loadingListMessage: alert.loadingListMessage || state.loadingListMessage,
  vehicleType: alert.vehicleType || state.vehicleType,
});

function* joinUpdateValuesAction(id: FormInstanceId, values: Partial<BookingFormState>) {
  yield put(setUpdateValues(id, values));
  yield fork(handleUpdateFormValues, updateValues(id, values));
}

export function* handleUpdateFormValues({
  payload: { id, values },
}: UpdateFormUpdateFormStateAction): SagaIterator<void> {
  const actions: Task[] = [];

  yield put(updateStarted(id));

  for (const key of Object.keys(values) as BookingFormStateKeys) {
    if (key === 'vehicleRegNo' || key === 'trailerRegNo') {
      actions.push(
        yield fork(onChangeVehicleRegistration, triggerFormUpdateSaga(id, key, values[key])),
      );
    } else if (key === 'vehicleType') {
      actions.push(yield fork(onChangeVehicleType, triggerFormUpdateSaga(id, key, values[key])));
    } else if (key === 'route') {
      actions.push(yield fork(onChangeRoute, triggerFormUpdateSaga(id, key, values[key])));
    } else if (key === 'customer') {
      actions.push(yield fork(onChangeCustomer, triggerFormUpdateSaga(id, key, values[key])));
    } else if (key === 'departureDate') {
      actions.push(yield fork(onChangeDepartureDate, triggerFormUpdateSaga(id, key, values[key])));
    } else if (key === 'cargoWeight') {
      actions.push(yield fork(onChangeCargoWeight, triggerFormUpdateSaga(id, key, values[key])));
    }
  }

  try {
    if (actions.length > 0) {
      yield join([...actions]);
    }
  } finally {
    yield put(updateFinished(id));
  }
}
