import { FC } from 'react';
import { HiddenFieldGate } from '../../../../../common-ui/HiddenFieldGate';
import { BasicBooking } from '../../../../../common/graphql/fragments/gql/BasicBooking';
import { useAllCustomersAsOptions } from '../../../../../common/graphql/useAllCustomersAsOptions';
import { useLoadingPreferenceOptions } from '../../../../../common/graphql/useLoadingPreferenceOptions';
import { useUpdateRegistrationNumberAlertApplied } from '../../../../../common/registrationNumberAlert/hooks/useUpdateRegistrationNumberAlertApplied';
import { customerReferencePlaceHolder } from '../../../../../common/utils/customerUtils';
import { GoodsApproval, GoodsApprovalType } from '../../../../../common/utils/GoodsApproval';
import { resolveLoadingPreferenceApproved } from '../../../../../common/utils/loadingPreferenceUtil';
import { ColumnId } from '../../../../../gql/graphql';
import { BookingFormState } from '../../../../../services/update-form/updateFormReducer';
import {
  transformCustomer,
  transformLoadingPreferenceOption,
} from '../../../grid/utils/optionTransformers';
import { BookingFields, isHidden, isVisible } from '../../utils/detailsUtils';
import { DetailsFieldCell } from '../DetailsFieldCell';
import { DropdownField } from '../fields/DropdownField';
import { TextInputField } from '../fields/TextField';
import { BookingSection } from './BookingSection';

interface LoadingPreferenceCustomerSectionProps {
  fieldsToShow: BookingFields;
  fieldsToEnable: BookingFields;
  disabled: boolean;
  formState: BookingFormState;
  booking: BasicBooking;
}

export const LoadingDetailsSection: FC<LoadingPreferenceCustomerSectionProps> = ({
  disabled,
  fieldsToShow,
  fieldsToEnable,
  formState,
  booking,
}) => {
  const loadingPreferenceApproval: GoodsApproval = {
    status: resolveLoadingPreferenceApproved(
      booking.loadingPreference,
      booking.loadingPreferenceStatusCode,
    ),
    type: GoodsApprovalType.LoadingPreference,
  };

  const customerOptions = useAllCustomersAsOptions();

  const showAccount = customerOptions.length > 1 && isVisible(fieldsToShow, ColumnId.CustIndex);

  const isDisabled = (field: ColumnId) => disabled || isHidden(fieldsToEnable, field);

  const { icon, arrivalNotepadApplied } = useUpdateRegistrationNumberAlertApplied(
    'Details',
    'loadingNote',
    undefined,
  );
  const [loadingPreferenceOptions] = useLoadingPreferenceOptions(formState?.route?.code);

  return (
    <BookingSection title={'Details'}>
      <HiddenFieldGate hidden={!showAccount}>
        <DetailsFieldCell
          label="Account"
          field={
            <DropdownField
              trackerCategory={'Details'}
              name={'customer'}
              transformOption={transformCustomer}
              disabled={isDisabled(ColumnId.CustIndex)}
              options={customerOptions}
            />
          }
        />
      </HiddenFieldGate>
      <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.CustomerReference)}>
        <DetailsFieldCell
          label="Customer reference"
          field={
            <TextInputField
              trackerCategory={'Details'}
              name={'customerReference'}
              maxLength={17}
              disabled={isDisabled(ColumnId.CustomerReference)}
              placeholder={customerReferencePlaceHolder(formState.customer)}
            />
          }
        />
      </HiddenFieldGate>
      <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.LoadingListMessage)}>
        <DetailsFieldCell
          label="Loading note"
          field={
            <TextInputField
              trackerCategory={'Details'}
              disabled={arrivalNotepadApplied || isDisabled(ColumnId.LoadingListMessage)}
              name={'loadingListMessage'}
              maxLength={132}
              buttonRight={icon}
            />
          }
        />
      </HiddenFieldGate>
      <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.LoadingPreferenceCode)}>
        <DetailsFieldCell
          label="Loading preference"
          field={
            <DropdownField
              trackerCategory={'Details'}
              name={'loadingPreference'}
              transformOption={transformLoadingPreferenceOption}
              disabled={isDisabled(ColumnId.LoadingPreferenceCode)}
              options={loadingPreferenceOptions}
            />
          }
          approved={loadingPreferenceApproval}
          flex={1}
        />
      </HiddenFieldGate>
    </BookingSection>
  );
};
