import { FC } from 'react';
import { transformYesNoOption } from '../../../manage/grid/utils/optionTransformers';
import { yesNoOptions } from '../../../manage/details/components/sections/common';
import { UpdateIntermodalTextInputField } from '../fields/UpdateIntermodalTextInputField';
import { UpdateIntermodalDropdownField } from '../fields/UpdateIntermodalDropdownField';
import { DimensionsFieldGrid } from '../../../manage/details/components/BookingDetailsEdit';
import { IntermodalDetailsFieldCell } from '../../common/IntermodalDetailsFieldCell';
import { UpdateIntermodalValidationWrapper } from '../UpdateIntermodalValidationWrapper';
import {
  AllIntermodalVehicleTypesQueryRefProps,
  useAllIntermodalVehicleTypesAsOptions,
} from '../../../../services/intermodal/hooks/useAllIntermodalVehicleTypesAsOptions';
import {
  getCraneableStatusApprovalInfo,
  transformIntermodalVehicleType,
} from '../../utils/detailsUtils';
import { UpdateIntermodalNumericTextInputField } from '../fields/UpdateIntermodalNumericTextInputField';
import {
  formatILUCodeString,
  formatRegistrationNumberString,
} from '../../../../common/utils/stringUtil';
import { CraneableStatus } from '../../../../gql/graphql';
import {
  MAX_LENGTH_EMPTY_VEHICLE_WEIGHT,
  MAX_LENGTH_HEIGHT,
  MAX_LENGTH_ILU_CODE,
  MAX_LENGTH_LENGTH,
  MAX_LENGTH_VEHICLE_REG,
  MAX_LENGTH_WIDTH,
} from '../../utils/fieldLengths';

interface UpdateIntermodalVehicleSectionProps extends AllIntermodalVehicleTypesQueryRefProps {
  craneableStatus: CraneableStatus;
}

export const UpdateIntermodalVehicleSection: FC<UpdateIntermodalVehicleSectionProps> = ({
  craneableStatus,
  vehicleTypesQueryRef,
}) => {
  const [vehicleTypes] = useAllIntermodalVehicleTypesAsOptions(vehicleTypesQueryRef);
  const craneableStatusApprovalInfo = getCraneableStatusApprovalInfo(craneableStatus);

  return (
    <>
      <UpdateIntermodalValidationWrapper
        renderField={isValid => (
          <IntermodalDetailsFieldCell
            label={'Vehicle registration'}
            className={'t_intermodal_vehicle_reg_field'}
            children={
              <UpdateIntermodalTextInputField
                trackerCategory={'IntermodalUpdate'}
                name={'vehicleReg'}
                isValid={isValid}
                maxLength={MAX_LENGTH_VEHICLE_REG}
                maskInput={formatRegistrationNumberString}
              />
            }
          />
        )}
        name={'vehicleReg'}
      />
      <UpdateIntermodalValidationWrapper
        renderField={isValid => (
          <IntermodalDetailsFieldCell
            label={'ILU code'}
            className={'t_intermodal_ilu_code_field'}
            children={
              <UpdateIntermodalTextInputField
                trackerCategory={'IntermodalUpdate'}
                name={'iluCode'}
                isValid={isValid}
                maxLength={MAX_LENGTH_ILU_CODE}
                maskInput={formatILUCodeString}
              />
            }
          />
        )}
        name={'iluCode'}
      />
      <IntermodalDetailsFieldCell
        label={'Vehicle type'}
        children={
          <UpdateIntermodalDropdownField
            trackerCategory={'IntermodalUpdate'}
            options={vehicleTypes}
            name={'vehicleType'}
            transformOption={transformIntermodalVehicleType}
          />
        }
      />
      <IntermodalDetailsFieldCell
        label={'Craneable'}
        children={
          <UpdateIntermodalDropdownField
            trackerCategory={'IntermodalUpdate'}
            transformOption={transformYesNoOption}
            name={'craneable'}
            options={yesNoOptions}
          />
        }
        flex={1}
        approved={craneableStatusApprovalInfo ?? undefined}
      />
      <UpdateIntermodalValidationWrapper
        renderField={isValid => (
          <IntermodalDetailsFieldCell
            label={'Empty vehicle weight (kg)'}
            children={
              <UpdateIntermodalNumericTextInputField
                maxLength={MAX_LENGTH_EMPTY_VEHICLE_WEIGHT}
                trackerCategory={'IntermodalUpdate'}
                isValid={isValid}
                name={'emptyWeight'}
                onlyIntegers
              />
            }
          />
        )}
        name={'emptyWeight'}
      />

      <DimensionsFieldGrid>
        <UpdateIntermodalValidationWrapper
          renderField={isValid => (
            <IntermodalDetailsFieldCell
              label={'Length (m)'}
              children={
                <UpdateIntermodalNumericTextInputField
                  maxLength={MAX_LENGTH_LENGTH}
                  trackerCategory={'IntermodalUpdate'}
                  isValid={isValid}
                  name={'length'}
                />
              }
            />
          )}
          name={'length'}
        />

        <UpdateIntermodalValidationWrapper
          renderField={isValid => (
            <IntermodalDetailsFieldCell
              label={'Width (m)'}
              children={
                <UpdateIntermodalNumericTextInputField
                  maxLength={MAX_LENGTH_WIDTH}
                  trackerCategory={'IntermodalUpdate'}
                  isValid={isValid}
                  name={'width'}
                />
              }
            />
          )}
          name={'width'}
        />

        <UpdateIntermodalValidationWrapper
          renderField={isValid => (
            <IntermodalDetailsFieldCell
              label={'Height (m)'}
              children={
                <UpdateIntermodalNumericTextInputField
                  maxLength={MAX_LENGTH_HEIGHT}
                  trackerCategory={'IntermodalUpdate'}
                  isValid={isValid}
                  name={'height'}
                />
              }
            />
          )}
          name={'height'}
        />
      </DimensionsFieldGrid>
    </>
  );
};
