import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { DropdownOption } from '../../use-cases/manage/grid/utils/optionTransformers';
import { stripDiacritics } from './diacritics';

interface Config<Option> {
  readonly ignoreCase?: boolean;
  readonly ignoreAccents?: boolean;
  readonly stringify?: (option: FilterOptionOption<Option>) => string;
  readonly trim?: boolean;
  readonly matchFrom?: 'any' | 'start';
}

const trimString = (str: string): string => str.replace(/^\s+|\s+$/g, '');
const defaultStringify = (option: any) => `${option.label} ${option.value}`;

/**
 * We made our own `createFilter` function with the builtin version
 *
 * Based on https://github.com/JedWatson/react-select @ `b867ff7eb5c02719cabd0db359cd34c6cbfb1c44`
 *
 *
 * Our patch changes the function to also check for matches in `option.value`
 *
 * Additions:
 * ```
 *   let optionValue = option.value;
 *       optionValue = option.value.toLowerCase();
 *       optionValue.substr(0, input.length) === input
 * ```
 */
export const createFilter =
  (config: Config<unknown>) =>
  (option: FilterOptionOption<DropdownOption<unknown>>, rawInput: string): boolean => {
    if (option.data?.hidden) {
      return false;
    }

    const { ignoreAccents, ignoreCase, stringify, trim, matchFrom }: Config<unknown> = {
      ignoreCase: true,
      ignoreAccents: true,
      stringify: defaultStringify,
      trim: true,
      matchFrom: 'any',
      ...config,
    };
    let input = trim ? trimString(rawInput) : rawInput;
    let candidate = trim ? trimString(stringify(option)) : stringify(option);
    let optionValue = option.value;
    if (ignoreCase) {
      input = input.toLowerCase();
      optionValue = option.value.toLowerCase();
      candidate = candidate.toLowerCase();
    }
    if (ignoreAccents) {
      input = stripDiacritics(input);
      candidate = stripDiacritics(candidate);
    }

    return (
      (matchFrom === 'start'
        ? candidate.substr(0, input.length) === input
        : candidate.indexOf(input) > -1) || optionValue.substr(0, input.length) === input
    );
  };

export const dropdownFilterConfig: Config<unknown> = {
  matchFrom: 'start',
};
