import { Select, SelectProps } from '@stenajs-webui/select';
import { createFilter, dropdownFilterConfig } from '../../common/react-select/filter';
import { Country } from '../../common/utils/countryUtil';
import { zIndices } from '../../common/zIndices';
import { getPortalRoot } from '../../use-cases/manage/grid/utils/getPortalRoot';
import { DropdownOption } from '../../use-cases/manage/grid/utils/optionTransformers';

const filterConfig = createFilter(dropdownFilterConfig);

const portalRoot = getPortalRoot();

export interface GroupedOption<TOption> {
  label: string;
  value: string;
  options: TOption[];
}

export type GroupedOptionsType<TOption> = ReadonlyArray<GroupedOption<TOption>>;

interface PropsWithHeight<TValue> extends SelectProps<DropdownOption<TValue>> {
  height: number;
}

export function DropdownGrid<TValue>(props: PropsWithHeight<TValue>) {
  return (
    <Select
      filterOption={filterConfig}
      menuPortalTarget={portalRoot}
      styles={{
        control: base => ({
          ...base,
          border: 'none',
          boxShadow: 'none',
          borderRadius: '0',
          flexWrap: 'nowrap',
          height: `${props.height - 2}px`,
          minHeight: `${props.height - 2}px`,
          '&:hover': {
            border: 'none',
          },
          lineHeight: '20px',
        }),
        valueContainer: base => ({
          ...base,
          padding: '0 2px',
        }),

        menuPortal: base => {
          return {
            ...base,
            display: 'inline-block',
            zIndex: zIndices.dropdown,
            left:
              typeof base.left === 'number'
                ? (base.left as number) +
                  (window.pageXOffset || document.documentElement.scrollLeft)
                : base.left,
          };
        },
      }}
      {...props}
    />
  );
}

interface DropdownProps<TValue> extends SelectProps<DropdownOption<TValue>> {
  isEdited?: boolean;
  isError?: boolean;
  width?: number;
}

export function Dropdown<TValue>({
  isEdited = false,
  isError = false,
  width,
  ...props
}: DropdownProps<TValue>) {
  return (
    <Select
      filterOption={filterConfig}
      menuPortalTarget={portalRoot}
      menuPlacement={'auto'}
      menuShouldScrollIntoView={false}
      variant={isError ? 'error' : undefined}
      styles={{
        singleValue: base => ({
          ...base,
          width: '100%',
        }),
        container: base => ({ ...base, width }),
        control: base =>
          isEdited
            ? {
                ...base,
                maxHeight: 36,
                flexWrap: 'nowrap',
                fontStyle: 'italic',
                fontWeight: 'bold',
              }
            : {
                ...base,
                maxHeight: 36,
                flexWrap: 'nowrap',
              },
        menuPortal: base => ({
          ...base,
          display: 'inline-block',
          zIndex: zIndices.dropdown,
          left:
            typeof base.left === 'number'
              ? (base.left as number) + (window.pageXOffset || document.documentElement.scrollLeft)
              : base.left,
        }),
      }}
      {...props}
    />
  );
}

export function DropdownModal<TValue>(props: DropdownProps<TValue>) {
  return <Dropdown<TValue> menuPortalTarget={undefined} {...props} />;
}

type DropdownShareOptionValue = DropdownOption<Country>;

export function DropdownShare(props: SelectProps<DropdownShareOptionValue>) {
  return (
    <Select<DropdownShareOptionValue>
      filterOption={filterConfig}
      styles={{
        menuPortal: base => ({
          ...base,
          zIndex: zIndices.dropdown,
        }),
        option: (base, { data }) => ({
          ...base,
          alignItems: 'center',
          display: 'flex',
          ':before': {
            content: '""',
            width: 26,
            height: 20,
            marginRight: 8,
            backgroundImage: `url('/img/flags/flags-iso/flat/svg/${data.data.countryCode}.svg')`,
            backgroundSize: 'contain',
          },
        }),
        singleValue: (base, { data }) => ({
          ...base,
          alignItems: 'center',
          display: 'flex',
          ':before': {
            content: '""',
            width: 26,
            height: 20,
            marginRight: 8,
            backgroundImage: `url('/img/flags/flags-iso/flat/svg/${data.data.countryCode}.svg')`,
            backgroundSize: 'contain',
          },
        }),
        control: base => ({
          ...base,
          minWidth: 150,
        }),
      }}
      {...props}
    />
  );
}
