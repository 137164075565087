import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Options } from 'react-select';
import { graphql } from '../../gql';
import {
  DropdownOption,
  transformSenderReceiverCountryOption,
} from '../../use-cases/manage/grid/utils/optionTransformers';
import { SenderReceiverCountry } from './fragments/gql/SenderReceiverCountry';
import { QueryResult } from './types/QueryResult';

export const senderReceiverCountryQuery = graphql(/* GraphQL */ `
  query SenderReceiverCountryQuery {
    viewer {
      senderReceiverCountries {
        items {
          ...SenderReceiverCountry
        }
      }
    }
  }
`);

export const transformCountryOptions = (
  data: SenderReceiverCountry[] | undefined,
): Options<DropdownOption<SenderReceiverCountry>> => {
  if (!data) {
    return [];
  }

  return data.map(transformSenderReceiverCountryOption);
};

export const useSenderReceiverCountryOptions = (): QueryResult<
  Options<DropdownOption<SenderReceiverCountry>>
> => {
  const { data, loading, error } = useQuery(senderReceiverCountryQuery, {
    fetchPolicy: 'cache-first',
  });

  return useMemo(
    () => [transformCountryOptions(data?.viewer.senderReceiverCountries.items), loading, error],
    [data, error, loading],
  );
};
